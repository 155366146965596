var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ma-3"},[_c('v-card',[_c('v-card-title',{staticClass:"color-26c6da"},[_vm._v("Revisão Interna")]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"solo":"","prepend-inner-icon":"mdi-magnify","label":"Pesquisar"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',[_c('v-select',{attrs:{"loading":_vm.loadingApresentantes,"multiple":"","solo":"","items":_vm.apresentantes,"label":"Selecione os apresentantes","item-value":"cd_apresentante","item-text":"cd_apresentante"},model:{value:(_vm.apresentantesSelected),callback:function ($$v) {_vm.apresentantesSelected=$$v},expression:"apresentantesSelected"}})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.doSearch}},[_vm._v("Filtrar")]),_c('v-btn',{staticClass:"ml-3",attrs:{"disabled":!_vm.search &&
                                _vm.apresentantesSelected.length <= 0 &&
                                _vm.selectedItems.length <= 0},on:{"click":function($event){return _vm.removerFiltros()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-filter-remove")]),_vm._v(" Remover Filtros")],1)],1),_c('v-col',{attrs:{"align":"end"}},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"disabled":_vm.selectedItems.length <= 0,"color":"yellow"},on:{"click":function($event){return _vm.confirmationAction('limpar')}}},[_vm._v("Limpar RVI")]),_c('v-btn',{staticClass:"ml-2 text-capitalize",attrs:{"disabled":_vm.selectedItems.length <= 0,"color":"orange"},on:{"click":function($event){return _vm.confirmationAction('corrigir')}}},[_vm._v("Corrigir")])],1)],1)],1)],1),_c('v-card',{staticClass:"my-3"},[_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"options":_vm.options,"show-select":"","item-key":"id_titulo","loading":_vm.loading,"headers":_vm.headers,"items":_vm.revisoes,"search":_vm.search,"footer-props":{
                    itemsPerPageOptions: [300, 600, 900],
                    itemsPerPageText: 'Itens por página:'
                },"server-items-length":_vm.totalResults,"loading-text":"Pesquisando títulos...","no-results-text":"Nenhum título encontrado..."},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.cd_status",fn:function(props){return [_c('span',{attrs:{"color":"primary","dark":""}},[_vm._v(_vm._s(props.item.cd_status)+" - "+_vm._s(props.item.ds_status))])]}}]),model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}})],1)],1),_c('v-dialog',{attrs:{"persistent":"","transition":"dialog-bottom-transition","width":"650px"},model:{value:(_vm.confirmationDialog),callback:function ($$v) {_vm.confirmationDialog=$$v},expression:"confirmationDialog"}},[_c('div',{staticClass:"text-center",attrs:{"width":"420px"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Confirmação")]),_c('v-card-text',[_c('v-row',[_c('v-col',[(_vm.actionSelected === 'limpar')?_c('span',[_vm._v("Você tem certeza que quer limpar RVIs dos titulos selecionados?")]):_c('span',[_vm._v("Você tem certeza que quer corrigir os titulos selecionados?")])])],1),_c('v-row',[_c('v-col',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headersConfirmation,"items":_vm.selectedItems,"items-per-page":300,"options":_vm.options,"footer-props":{
                                    itemsPerPageOptions: [300],
                                    itemsPerPageText: 'Itens por página:'
                                }},on:{"update:options":function($event){_vm.options=$event}}})],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.confirmationDialog = false}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.action(_vm.actionSelected)}}},[_vm._v("Confirmar")])],1)],1)],1)]),(_vm.snackbar)?_c('Snackbar',{attrs:{"mostrarSnackbar":_vm.snackbar,"corSnackbar":_vm.colorSnackbar,"mensagemSnackbar":_vm.mensagemSnackbar},on:{"fecharSnackbar":function($event){_vm.snackbar = false}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }